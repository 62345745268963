import { HeadProps } from "gatsby";
import React, { Fragment, useEffect, useState } from "react";
import Footer from "../features/footer/Footer";
import Navbar from "../features/navbar/Navbar";
import Seo from "../features/seo/Seo";
import { useCurrentUserQuery } from "../features/user/userApiSlice";

const Profile = () => {
  const [memberSince, setMemberSince] = useState(null);
  const { data: currentUser, isFetching } = useCurrentUserQuery();

  useEffect(() => {
    if (currentUser?.is_authenticated) {
      const date = new Date(currentUser.data.created * 1000);
      const options: Intl.DateTimeFormatOptions = {
        month: "long",
        year: "numeric",
      };
      setMemberSince(new Intl.DateTimeFormat("default", options).format(date));
    }
  }, [isFetching]);

  return (
    <Fragment>
      <div className="min-h-screen">
        <Navbar />
        <div className="py-24 px-10">
          <div className="md:w-3/4 lg:w-1/2 mx-auto">
            {currentUser?.is_authenticated && (
              <>
                <h1 className="mb-8 text-5xl font-bold text-neutral-700">
                  {currentUser.data.name}
                </h1>
                <p>Member Since {memberSince}</p>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Profile;

export const Head = (props: HeadProps) => {
  return (
    <Fragment>
      <Seo
        title="Profile | Giancoli Answers"
        pathname={props.location.pathname}
      />
      <meta name="robots" content="noindex" />
    </Fragment>
  );
};
